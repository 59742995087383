<template>
  <div key="finish-step" :class="{
      'opacity-0': !visible
    }" class="flex items-stretch transition-opacity min-h-xl">
    <div class="flex flex-col items-start justify-center order-1 p-6 md:order-0 md:p-8 lg:p-12 md:w-c6">

      <div v-if="!askForDemographics" class="mt-0 mb-auto font-light text-14 opacity-85 text-theme-fg">
        Completed <svg-icon icon="circle-tick" class="inline-block ml-1 -mt-px align-middle fill-current text-theme-highlight" size="4" />
      </div>

      <div v-else class="mt-0 mb-auto font-bold text-14 text-theme-highlight">
        One more thing...
      </div>

      <template v-if="askForDemographics">
        <div class="mt-4 font-light max-w-88 text-16 leading-medium">
          <p class="mb-8 font-light leading-tight text-28">Before you go, could you tell us a bit more about yourself?</p>

          <form @submit.prevent="submitDemographics" class="max-w-88" action="/">
            <div class="flex items-center">
              <label class="mr-4 font-normal whitespace-no-wrap text-16">
                Postcode:
              </label>
              <div class="relative flex-1 ml-auto max-w-50 md:max-w-64">
                <input placeholder="e.g. N1" type="text" class="flex-grow w-full h-10 pl-4 pr-2 font-sans font-light bg-white border rounded shadow border-grey-20 text-16" v-model="postcode" />
              </div>
            </div>
            <div class="flex items-center mt-2">
              <label class="mr-4 font-normal whitespace-no-wrap text-16">
                Age:
              </label>
              <div class="relative flex-1 ml-auto max-w-50 md:max-w-64">
                <select class="w-full h-10 pl-4 pr-2 font-sans font-light bg-white border rounded shadow appearance-none border-grey-20 text-16" v-model="age">
                  <option value=""></option>
                  <option value="under25">24 and under</option>
                  <option value="25-34">25 – 34</option>
                  <option value="35-44">35 – 44</option>
                  <option value="45-59">45 – 59</option>
                  <option value="over60">60+</option>
                </select>
                <div class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none text-grey-20">
                  <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                </div>
              </div>
            </div>
            <div class="flex items-center mt-2">
              <label class="mr-4 font-normal whitespace-no-wrap text-16">
                Gender:
              </label>
              <div class="relative flex-1 ml-auto max-w-50 md:max-w-64">
                <select class="w-full h-10 pl-4 pr-2 font-sans font-light bg-white border rounded shadow appearance-none border-grey-20 text-16" v-model="gender">
                  <option value=""></option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                <div class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none text-grey-20">
                  <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                </div>
              </div>
            </div>
            <div class="mt-4 font-light text-12 leading-medium">
              <p><em>By submitting this form you, acknowledge having read and understood our <a href="/privacy-policy/" target="_blank" class="text-theme-highlight">Privacy Policy</a>.</em></p>
            </div>
            <div class="flex justify-end w-full mt-2">
              <button type="submit" class="h-10 px-6 mt-4 font-serif antialiased italic font-normal text-white rounded-full cursor-pointer bg-theme-highlight text-14">Submit</button>
            </div>
          </form>
        </div>
      </template>
      <template v-if="!askForDemographics">
        <div class="w-full my-auto max-w-94">
          <svg-icon icon="party" class="mt-auto mb-2 party-hat" />
          <h2 class="mb-2 font-medium text-28">You're all done!</h2>
          <div class="mb-2 font-light leading-tight text-28">Thanks for completing the poll.</div>
          <template v-if="!complete">
            <div class="mt-4 font-light max-w-88 text-16 leading-medium">
                <p>If you want to keep up to date with the progress of this project then sign up for updates below.</p>
            </div>
            <form @submit.prevent="finish" class="flex items-end w-full mt-8 max-w-88" action="/">
              <label class="flex-grow flex-shrink font-light text-14">
                <span class="visually-hidden">Enter your email</span>
                <input placeholder="me@example.com" type="email" class="flex-grow w-full h-10 pl-4 pr-2 font-sans font-light bg-white border border-grey-20 text-16" v-model="email" />
              </label>
              <button type="submit" class="flex-grow-0 flex-shrink-0 h-10 px-6 font-serif antialiased italic font-normal text-white cursor-pointer text-14 bg-theme-highlight hover:bg-theme-dark">Sign Up</button>
            </form>
          </template>
          <div v-else class="mt-4 font-serif italic font-normal text-18 text-theme-highlight">
              {{ message }}
          </div>
        </div>
      </template>

      <div class="h-px mt-auto mb-0"></div>

    </div>
    <div class="relative self-stretch md:order-1 order-0 md:w-c6">
      <img :src="image" class="relative z-0 hidden object-cover object-center w-full h-full md:block" />
      <router-link id="card-close-button" class="absolute bottom-0 right-0 m-8 text-white border-white button bg-black-40 z-1" to="/">Close</router-link>
    </div>

  </div>
</template>

<script>

import { mapState } from 'vuex'
import {getIsDemographicsFinished, setDemographicsFinished, getIsUserFinished, setUserFinished} from "@/vue/store/_ajax"

export default {
  layout: 'card-overlay',
  data() {
    return {
      locked: false,
      email: '',
      locked: false,
      askForDemographics: true,
      fullName: '',
      email: '',
      age: '',
      gender: '',
      postcode: '',
      complete: false,
      message: '',
      visible: false,
    }
  },
  watch: {
    email(a) {
      if(a) {
        this.status = ''
      }
    }
  },
  computed: {
    ...mapState( 'polls', [
      'image',
      'slug',
    ]),
  },
  mounted() {
    var APP = window.APP || {};

    this.visible = false
    this.complete = getIsUserFinished()
    this.askForDemographics = ! getIsDemographicsFinished()

    document.body.classList.add('fix-body')
    setTimeout( () => {
      this.visible = true
    }, 400 )
  },
  beforeRouteLeave( to, from, next ) {
    // We're closing...
    if ( to.path === "/" ) {
      document.body.classList.remove('fix-body')
      setTimeout( () => {
        // console.log("Next?")
        next();
      }, 200 );
    } else {
      next();
    }
  },
  created() {
    if ( !this.slug ) {
      this.$store.dispatch('polls/load', this.$route.params.poll);
    }
  },
  methods: {
    checkCanSubmit() {
      if ( !this.email ) {
        return false
      }
      return true
    },
    async submitDemographics() {

      const success = await this.$store.dispatch('question/demographics', {
        fullName: this.fullName,
        email: this.email,
        postcode: this.postcode,
        age: this.age,
        gender: this.gender,
        name: '',
        address: '',
        email: '',
      })

      if ( success ) {
        setDemographicsFinished();
        this.askForDemographics = false
      } else {
        this.locked = false
      }

    },
    async finish() {

      if( !this.checkCanSubmit() ) return

      const success = await this.$store.dispatch('question/finish', {
        email: this.email,
      })

      if ( success ) {
        setUserFinished()
        this.complete = true
      } else {
        this.locked = false
      }
    }
  },
}
</script>

<style scoped>
.party-hat {
  transform: rotate(-30deg);
  width: 58px;
  height: 57px;
  @apply text-theme-highlight;
  @apply fill-current;
}
</style>
